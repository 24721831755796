import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { campaignClient, publicCampaignClient } from "../../api";
import { useQuery } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { PageLoader } from "../../Components";

type WinnerForm = {
    region: string;
    winners: {
        storeNumber: string;
        storeName: string;
    }[];
};

const Regions = [
    {
        name: "Atlantic",
        value: "atl",
    },
    {
        name: "West",
        value: "west",
    },
    {
        name: "Ontario",
        value: "ont",
    },
    {
        name: "Quebec",
        value: "quebec",
    },
];

const LotteryWinner = ({ type }: { type: string }) => {
    const [loading, setLoading] = useState(false);
    let default_values: WinnerForm[] = [];

    Regions.forEach((region) => {
        const value = [
            {
                region: region.value,
                winners: [
                    { storeNumber: "", storeName: "" },
                    { storeNumber: "", storeName: "" },
                    { storeNumber: "", storeName: "" },
                    { storeNumber: "", storeName: "" },
                ],
            },
        ];
        default_values.push(...value);
    });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors, isDirty },
    } = useForm<{ winner: WinnerForm[] }>({
        mode: "onTouched",
        criteriaMode: "all",
        defaultValues: { winner: default_values },
    });

    const {
        isLoading: isLoadingLeaderBoard,
        data: lotteryWinners,
        refetch,
    } = useQuery(
        [`getLottery-${type}`],
        async () => {
            const res: { winners: WinnerForm[] } = await publicCampaignClient.call("getLotteryWinners", {
                type: type,
            });

            if (res.winners && res.winners?.length) {
                reset({ winner: res.winners });
            }
        },
        { refetchOnWindowFocus: false }
    );

    const handleSave = async (data: { winner: WinnerForm[] }) => {
        if (loading) {
            return;
        }
        setLoading(true);

        try {
            const res = await Promise.all(
                data.winner?.map(async (region) => {
                    await campaignClient.call("saveLeaderboard", {
                        region: region.region,
                        winners: region.winners,
                        type: type,
                    });
                })
            );
            if (res) {
                refetch();
                showToast({
                    content: "saved.",
                    duration: 3000,
                    error: false,
                });
            }
            setLoading(false);
        } catch (e) {
            showToast({
                content: "error while saving the leaderboard.",
                duration: 3000,
                error: true,
            });
        }
    };

    return (
        <>
            {isLoadingLeaderBoard ? (
                <PageLoader />
            ) : (
                <form onSubmit={handleSubmit(handleSave)}>
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <b>Region</b>
                                </td>
                                <td>
                                    <b>Store Number</b>
                                </td>
                                <td>
                                    <b>Store Name</b>
                                </td>
                            </tr>
                        </thead>

                        <tbody>
                            {watch().winner.map((win, i) => (
                                <React.Fragment key={i}>
                                    {win.winners?.map((s, j) => (
                                        <tr className="tableRowCustom" key={j}>
                                            {!j ? <td> {Regions.find((r) => r.value === win.region)?.name}</td> : <td></td>}

                                            <>
                                                <td>
                                                    <input {...register(`winner.${i}.winners.${j}.storeNumber`)} />
                                                </td>
                                                <td>
                                                    <input {...register(`winner.${i}.winners.${j}.storeName`)} />
                                                </td>
                                            </>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <button className="btn__success" type="submit">
                        Save changes
                    </button>
                </form>
            )}
        </>
    );
};

export default LotteryWinner;
